/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lora:wght@400;700&family=Roboto:wght@400;500&display=swap');

.root {
  background-color: #f6f0e4;
  color: #000000;
  font-family: 'Times New Roman', serif;
  overflow-x: hidden;
  --primary-color: #f6f0e4;
}

body {
  font-family: 'Lora', serif; /* Use Lora for general body text */
  color: var(--primary-color);
}

.appBar {
  background-color: rgba(0, 0, 0, 0.7) !important; /* Dark transparent overlay for better contrast */
  box-shadow: none;
  padding: 10px 40px;
  position: fixed;
  width: 100%;
  z-index: 3;
  top: 0;
  display: flex;
  align-items: center;
}

.logo {
  font-family: 'Playfair Display', serif !important; /* Use Playfair Display for the logo */
  font-weight: 700;
  font-size: 2.5rem;
  color: #ffffff;
}

.nav {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
}

.navButton {
  color: #ffffff !important; /* White for AppBar menu items */
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  transition: color 0.3s ease, transform 0.3s ease;
  padding: 8px 15px;
  border-radius: 20px;
  font-family: 'Playfair Display', serif !important; /* Make navigation hover effect also use Playfair Display for emphasis */
}

.navButton:hover {
  color: #e1c699 !important;
  transform: translateY(-2px);
  font-family: 'Playfair Display', serif; /* Make navigation hover effect also use Playfair Display for emphasis */
}

.tagline {
  height: 60vh; /* Reduce height to 90% */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  overflow: hidden;
  background-color: #2e1e47;
  z-index: 3; 
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(50%); /* Add opaque shade to the video */
}

.tagline-text {
  position: relative;
  z-index: 4; /* Set to be above the video and other elements */
  font-size: 4rem;
  font-weight: 700;
  color: #ffffff !important; /* Bright white to stand out against the video */
  text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.7); /* Add shadow for better contrast */
  padding: 20px;
}
.tagline-text,
.section h2,
.section h4 {
  font-family: 'Playfair Display', serif !important; /* Use Playfair Display for headlines */
  font-weight: 700;
}
.section {
  padding: 60px 20px;
  text-align: left;
  background-color: transparent; /* Remove white background */
  color: #000000 !important; /* Black font for rest of the sections */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-bottom: 1px solid #e0e0e0; /* Add line to separate sections */
}

.section h2, .section h4 {
  align-self: flex-start; /* Align headings to the top of the section */
}

.section p {
  max-width: 80%;
}

.why-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}

.why-item {
  width: calc(100% - 40px);
  max-width: 300px;
  padding: 30px;
  border-radius: 20px;
  background-color: #2e1e47 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff !important;
}

.why-item .icon {
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 15px;
}

.products-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #2e1e47 !important;
  color: #ffffff !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
  padding: 40px;
}

.product {
  padding: 30px;
  background-color: #f6f0e4 !important;
  border-radius: 20px;
  color: #2e1e47 !important; /* Dark font for better readability */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for distinction */
}

.product:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.product::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.product:hover::before {
  opacity: 1;
}

.product-content {
  position: relative;
  z-index: 2;
}

.product-content h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #2e1e47;
}

.footer {
  background-color: #2e1e47;
  color: #ffffff;
  padding: 40px 20px;
  text-align: center;
}

.socialLinks {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 15px;
  color: #000000 !important; /* Black font for list items */
}

ul li strong {
  color: #2e1e47;
}

@media (max-width: 768px) {
  .appBar {
    flex-direction: column;
    padding: 15px;
  }
  .nav {
    gap: 15px;
    margin-top: 10px;
  }

  .tagline {
    height: 70vh;
  }
  .section {
    flex-direction: column;
  }
  .products-grid {
    grid-template-columns: 1fr;
  }
}
